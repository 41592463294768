<template>
    <div class="resetPassword xi">
        <nh-com></nh-com>
        <div class="banner">
            <div class="banner-wrap clearfix">
                <div class="banner-text">
                    <h3>个人中心</h3>
                    <p>当前位置：
                        <router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>个人中心</a></p>
                </div>
            </div>
        </div>
        <div class="personal-content">
            <div class="personal-box clearfix">
                <div class="personal-left">
                    <hd-img></hd-img>
                </div>
                <div class="personal-right">
                    <div class="personal-right-tit">
                        <i class="iconfont">&#xe62c;</i><span>添加<em>复诊</em></span>
                    </div>
                    <div class="patientBox">
                        <div class="patient">
                            <div class="pList">
                                <div class="pListLeft">
                                    <div style="width: 70px">{{patientList.name}}</div>
                                    <div style="margin-left: 10px">{{patientList.phone}}</div>
                                    <i v-if="patientList.sex == true" class="el-icon-male" style="color:#15C6FF;margin-left: 10px"></i>
                                    <i v-if="patientList.sex == false" class="el-icon-female" style="color:#FF75D1;margin-left: 10px"></i>
                                    <div style="margin-left: 42px">{{patientList.age}}</div>
                                </div>
                                
                            </div>
                            <div style="margin-left: 40px">{{patientList.region != null ? patientList.region.fullName : '暂未填写'}}{{patientList.address != null ? patientList.address : '暂未填写'}}</div>
                        </div>
                    </div>
                    <!-- 患者列表 -->
                  <el-form :model="diagnoseForm" ref="diagnoseForm" :rules="rules" label-width="100px" style="width: 850px">
                            <el-form-item label="主诉" prop="main">
                                <el-input v-model="diagnoseForm.main"></el-input>
                            </el-form-item>
                            <el-form-item label="现病史">
                                <el-input type="textarea" v-model="diagnoseForm.current"></el-input>
                            </el-form-item>
                            <el-form-item label="既往史">
                                <el-input type="textarea" v-model="diagnoseForm.past"></el-input>
                            </el-form-item>
                            <el-form-item label="过敏史">
                                <el-input v-model="diagnoseForm.Irritability"></el-input>
                            </el-form-item>
                            <el-form-item label="家族史">
                                <el-input v-model="diagnoseForm.family"></el-input>
                            </el-form-item>
                            <el-form-item label="疾病诊断" prop="diseaseDiagnose">
                                <el-input v-model="diagnoseForm.diseaseDiagnose"></el-input>
                            </el-form-item>
                            <el-form-item label="体格检查">
                                <el-descriptions class="margin-top" :column="4" border>
                                    <el-descriptions-item label="体温"><el-input class="small-input" v-model="diagnoseForm.temperature" size="small"></el-input>℃</el-descriptions-item>
                                    <el-descriptions-item label="身高"><el-input class="small-input" v-model="diagnoseForm.height" size="small"></el-input>CM</el-descriptions-item>
                                    <el-descriptions-item label="体重"><el-input class="small-input" v-model="diagnoseForm.weight" size="small"></el-input>KG</el-descriptions-item>
                                    <el-descriptions-item label="BMI"><el-input class="small-input" v-model="diagnoseForm.BMI" size="small"></el-input></el-descriptions-item>
                                    <el-descriptions-item label="心率"><el-input class="small-input" v-model="diagnoseForm.heartRate" size="small"></el-input></el-descriptions-item>
                                    <el-descriptions-item label="血压"><el-input class="small-input" v-model="diagnoseForm.heightTension" size="small"></el-input>/<el-input v-model="diagnoseForm.lowTension" class="small-input" size="small"></el-input>mmHg</el-descriptions-item>
                                    <el-descriptions-item label="呼吸"><el-input class="small-input" v-model="diagnoseForm.breathe" size="small"></el-input></el-descriptions-item>
                                    <el-descriptions-item label="脉搏"><el-input class="small-input" v-model="diagnoseForm.Pulse" size="small"></el-input></el-descriptions-item>

                                </el-descriptions>
                            </el-form-item>
                            <el-form-item label="症状照片">
                                <el-upload
                                        :headers="headers"
                                        :action="FileUploadUrl"
                                        list-type="picture-card"
                                        :limit="5"
                                        multiple
                                        :on-preview="DiagnoseImagePreview"
                                        :on-remove="DiagnoseImageRemove"
                                        :on-success="DiagnoseImageUpload"
                                        :file-list="ImageContentList">
                                    <i class="el-icon-plus"></i>
                                    <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，最多上传5张，单张大小不超过1M</div>
                                </el-upload>
                                <el-dialog :visible.sync="Image.dialogVisible">
                                    <img width="100%" :src="Image.dialogImageUrl" alt="">
                                </el-dialog>
                            </el-form-item>
                            <el-form-item label="上传处方">
                                <el-upload
                                        :headers="headers"
                                        :action="FileUploadUrl"
                                        list-type="picture-card"
                                        :limit="5"
                                        multiple
                                        :on-preview="handleImagePreview"
                                        :on-remove="handleImageRemove"
                                        :on-success="handleImageUpload"
                                        :file-list="ImageContentList">
                                    <i class="el-icon-plus"></i>
                                    <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，最多上传5张，单张大小不超过1M</div>
                                </el-upload>
                                <el-dialog :visible.sync="Image.dialogVisible">
                                    <img width="100%" :src="Image.dialogImageUrl" alt="">
                                </el-dialog>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="onSubmit('diagnoseForm')">保存</el-button>
                            </el-form-item>
                        </el-form>
                </div>
            </div>
        </div>
        <ft-com></ft-com>
    </div>
</template>

<script>
    import ftCom from '../../components/footer'
    import nhCom from "../../components/navheader";
    import hdImg from "../../components/headerImg";
    import {patientMain} from "../../components/patienTmanagement/patienTmanagement";
    import { Region } from "../../components/region/Region";
    export default {
        name: "addRecord",
        computed: {
            headers() {
                return {
                    "Authorization": 'Bearer' + ' ' + JSON.parse(sessionStorage.getItem('Token'))
                }
                
            }
        },
        components: {
            ftCom,
            nhCom,
            hdImg
        },
        data() {
            var region = new Region(this.TokenClient, this.Services.Authorization);
            var patient= new patientMain(this.TokenClient, this.Services.Authorization)
            return {
                patientDomain:patient,
                Region: region,
                FileUploadUrl: this.Services.Authorization+'/api/File/upload',
                Image: {
                    //单击图片上传控件中的图片时，放大显示
                    dialogVisible: false,
                    dialogImageUrl: '',
                },
                ImageContent: [],//图片列表
                ImageContentList: [],//图片上传控件加载的数据
                DiagnoseContent: [],//图片列表
                diagnoseForm:{
                    type: 1,
                    /*main: "",
                    current: "",
                    past: "",
                    Irritability: "",
                    family: "",
                    diseaseDiagnose: "",

                    BMI: "" ,
                    Pulse: "",
                    breathe: "",
                    heartRate: "",
                    height: "",
                    heightTension: "",
                    lowTension: "",
                    temperature: "" ,
                    weight:"",*/

                    DiagnoseImgList: [],
                    PrescriptionImgList: [],
                },
                rules:{
                    main:[
                        { required: true, message: '请输入主诉', trigger: 'blur' },
                    ],
                    diseaseDiagnose:[
                        { required: true, message: '请输入疾病诊断', trigger: 'blur' },
                    ],
                },
                errStr: "",
                RegionData: [],
                patientList:[]
            };
        },
        mounted(){
           this.getList()
        },
        methods: {
            handleImageUpload(response, file, fileList) {
                var _this = this;
                response.forEach(function (file) {
                    var imgarr = {
                        "filePath": file.completeFilePath,
                        "fileName": file.fileName,
                        "fileHash": file.fileHash,
                        "fileExt": file.fileExtension,
                    }

                    _this.ImageContent.push(imgarr);
                });
                _this.diagnoseForm.PrescriptionImgList = _this.ImageContent;

            },
            handleImagePreview(file) {
                this.Image.dialogImageUrl = file.url;
                this.Image.dialogVisible = true;
            },
            handleImageRemove(file, fileList) {
                var _this = this;
                const filePath = file.response[0].completeFilePath;
                //2.从pics数组中找到图片对应的索引值
                //形参‘x’，是数组pic里的每一项
                const i = _this.ImageContent.findIndex(x =>
                    x.completeFilePath == filePath);
                //调用数组的splice方法，把图片的信息对象从pics数组中移除
                _this.ImageContent.splice(i,1);
                _this.diagnoseForm.PrescriptionImgList = _this.ImageContent;
            },
            DiagnoseImagePreview(file) {
                this.Image.dialogImageUrl = file.url;
                this.Image.dialogVisible = true;
            },
            DiagnoseImageRemove(file, fileList) {
                var _this = this;
                const filePath = file.response[0].completeFilePath;
                //2.从pics数组中找到图片对应的索引值
                //形参‘x’，是数组pic里的每一项
                const i = _this.DiagnoseContent.findIndex(x =>
                    x.completeFilePath == filePath);
                //调用数组的splice方法，把图片的信息对象从pics数组中移除
                _this.DiagnoseContent.splice(i,1);
                _this.diagnoseForm.DiagnoseImgList = _this.DiagnoseContent;
            },
            DiagnoseImageUpload(response, file, fileList) {
                var _this = this;
                response.forEach(function (file) {
                    var imgarr = {
                        "filePath": file.completeFilePath,
                        "fileName": file.fileName,
                        "fileHash": file.fileHash,
                        "fileExt": file.fileExtension,
                    }

                    _this.DiagnoseContent.push(imgarr);
                });
                _this.diagnoseForm.DiagnoseImgList = _this.DiagnoseContent;

            },
            getList () {
                var _this = this;
                _this.patientDomain.GetPatientDetail(_this.$route.query.patientId,function (data) {
                    _this.patientList = data.data
                    _this.Region.getRegions(_this.patientList.id,function(data){
                        _this.patientList.region = data.data
                       })

                },function (error) {
                    // _this.$message.error('修改失败！旧密码不正确，请重新输入。');
                })
            },
            InitialRegion() {
                var _this = this;
                _this.Region.Regions(null,
                    function (data) { _this.RegionData = data.data; },
                    function (err) { console.log(err); });
            },
            LasyLoadRegion(node, resolve) {
                var _this = this;
                _this.Region.Regions(node.value,
                    function (data) {
                        for (var dataIndex = 0; dataIndex < data.data.length; dataIndex++) {
                            data.data[dataIndex].leaf = data.data[dataIndex].regionLevel >= 3;
                        }
                        resolve(data.data);
                    },
                    function (err) {
                        resolve([]);
                    });
            },
             onSubmit(formName) {
                var _this = this
                    _this.$refs[formName].validate((valid) => {
                        if (valid) {
                            _this.diagnoseForm.PatientId = _this.$route.query.patientId
                            _this.patientDomain.AddDiagnose(_this.diagnoseForm,
                                function (data) {
                                    _this.$alert('添加成功', '提示', {
                                        confirmButtonText: '确定并返回',
                                        type: 'success',
                                        callback: action => {
                                            _this.$router.go(-1)
                                        }
                                    });
                                },
                                function (error) {
                                    console.log(error);
                                });
                        } else {
                            console.log('error submit!!');
                            return false;
                        }
                    });
                }
        }
    }
</script>

<style scoped>
    @import "../../assets/css/footer.css";

    .banner {
        width: 100%;
        height: 160px;
        background: url("../../assets/img/news_banner.jpg") center no-repeat;
    }
    .patientheard{
        display: flex;
        align-items: center;
    }
    .patientBox {
        padding: 21px 42px;
        color: #808080
    }
    .patient {
        padding: 21px 0  15px 0;
        border-bottom: 1px dashed #CCCCCC;
    }
    .pList{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .pListLeft {
           display: flex;
        align-items: center;
    }
    .banner-wrap {
        position: relative;
        width: 1200px;
        height: 100%;
        margin: 0 auto;
    }

    .banner-text {
        float: left;
    }

    .banner-text h3 {
        padding-top: 35px;
        font-size: 26px;
        font-weight: normal;
        color: #ffffff;
    }

    .banner-text p {
        font-size: 14px;
        color: #ffffff;
        margin-top: 20px;
    }

    .banner-text p a {
        color: #ffffff;
        font-size: 14px;
        margin: 0 5px;
    }
    /**/
    .personal-content{
        background: #f7f7f7;
        padding: 40px 0;
    }
    .personal-box{
        width: 1200px;
        margin: 0 auto;
    }
    .personal-left{
        float: left;
    }
    .personal-right{
        float: right;
        width: 900px;
        background: #FFFFFF;
        box-sizing: border-box;
    }
    .personal-right-tit{
        border-bottom: 1px solid #e5e5e5;
        padding: 20px;
    }
    .personal-right-tit .iconfont{
        color: #04a290;
        font-size: 24px;
        margin-right: 5px;
    }
    .personal-right-tit span{
        display: inline-block;
        font-size: 24px;
        font-weight: 700;
        color: #333333;
    }
    .personal-right-tit span em{
        font-style: normal;
        color: #04a290;
    }
    .personal-right>form{
        padding: 30px 15px;
        width: 460px;
    }
</style>
<style>
    .personal-center{
        display: none;
    }
</style>
